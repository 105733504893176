<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row gutter-b">
      <div class="col-xxl-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Form User</h6>
          </template>
          <div class="mb-4">
            <h6 class="mb-1">Name</h6>
            <b-form-input v-model="user.name" placeholder="Name"></b-form-input>
          </div>
          <div class="mb-4">
            <h6 class="mb-1">Email</h6>
            <b-form-input
              v-model="user.email"
              placeholder="Email address"
              type="email"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <h6 class="mb-1">Password</h6>
            <b-form-input
              v-model="user.password"
              placeholder="Password"
              type="password"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <h6 class="mb-1">Confirm Password</h6>
            <b-form-input
              v-model="user.confirm_password"
              placeholder="Password"
              type="password"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <h6 class="mb-1">Phone Number</h6>
            <b-form-input
              v-model="user.phone_number"
              placeholder="Phone number"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <h6 class="mb-1">Birtdate</h6>
            <b-form-input
              v-model="user.dob"
              placeholder="Birtdate"
              type="date"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <h6>Photo</h6>
            <b-form-file
              ref="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="handleFile"
            ></b-form-file>
          </div>
          <template v-slot:footer>
            <b-button variant="primary" @click="createUser" :disabled="loading">
              {{ loading ? "Loading..." : "Submit" }}
            </b-button>
          </template>
        </b-card>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "CreateBrodcast",
  data() {
    return {
      user: {
        name: null,
        email: null,
        phone_number: null,
        dob: null,
        photo: null,
        password: null,
        confirm_password: null,
      },
      photo: null,
      loading: false,
    };
  },
  components: {},
  mounted() {
    let lastBreadcrumb = "";
    if (this.$route.name === "user-create")
      lastBreadcrumb = { title: "Create User" };
    else lastBreadcrumb = { title: "Edit User" };
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "User", route: "/user" },
      lastBreadcrumb,
    ]);
  },
  methods: {
    createUser() {
      let formData = new FormData();
      formData.append("name", this.user.name);
      formData.append("email", this.user.email);
      formData.append("phone_number", this.user.phone_number);
      formData.append("dob", this.user.dob);
      formData.append("password", this.user.password);
      formData.append("photo", this.photo);

      if (this.user.password != this.user.confirm_password) {
        this.$bvModal.msgBoxOk(`Confirmation password is not match`, {
          title: "Error",
          okTitle: "Close",
          size: "sm",
          buttonSize: "sm",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      } else {
        this.loading = true;
        this.$axios
          .post(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/user`, formData)
          .then(() => {
            this.loading = false;
            this.$router.go(-1);
          })
          .catch((error) => {
            this.loading = false;
            this.$bvModal.msgBoxOk(`${error.response.data.message}`, {
              title: "Error",
              okTitle: "Close",
              size: "sm",
              buttonSize: "sm",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          });
      }
    },
    handleFile(e) {
      const file = e.target.files[0];
      this.photo = file;
    },
  },
};
</script>
<style lang="scss"></style>
